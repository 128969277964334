import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userBaseInfo: null
    },
    mutations: {
        updateUserBaseInfo (state, action) {
            state.userBaseInfo = action
        }
    },
    actions: {
    },
    modules: {
    }
})
