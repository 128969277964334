import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        redirect: '/'
    },
    // 专家墙相关
    {
        name: 'profitPlan',
        path: '/xboss/profit/plan',
        component: () => import('@/views/xboss/zjq/profit.vue')
    },
    {
        name: 'profitPlanLq',
        path: '/xboss/profit/planLq',
        component: () => import('@/views/xboss/zjq/profitLq.vue')
    },
    {
        name: 'profitGoals',
        path: '/xboss/profit/goal',
        component: () => import('@/views/xboss/zjq/goal.vue')
    },
    // 三场盈利相关
    {
        name: 'three',
        path: '/xboss/three/plan',
        component: () => import('@/views/xboss/three/profit.vue')
    },
    // 竞彩长串
    {
        name: 'long',
        path: '/xboss/profit/long',
        component: () => import('@/views/long/long.vue')
    },
    // 情报相关
    {
        name: 'report',
        path: '/xboss/report',
        component: () => import('@/views/xboss/report.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router;
